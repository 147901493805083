import * as React from "react";
import { Link } from "gatsby";
import icon from "../images/icon.png";

// styles
const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <title>FXC.NINJA</title>
      <div class="flex flex-col container mx-auto content-center">
        <img class="w-24 h-24" src={icon} alt="Logo" />
        <h1 style={headingStyles}>My Time</h1>
        <Link to="/terms_of_service">Terms of Service</Link>
        <Link to="/privacy_policy">Privacy Policy</Link>
        <a href="mailto:support@fxc.ninja">support@fxc.ninja</a>
      </div>
    </main>
  );
};

export default IndexPage;
